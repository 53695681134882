
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { AppState } from './store';
import { Adventure } from './types';

export interface ExploreState {
  activeAdventure: Adventure | null;
}

const initialState: ExploreState = {
  activeAdventure: null,
};

export const exploreSlice = createSlice({
  name: 'explore',
  initialState,
  reducers: {
    setActiveAdventure: (state, action: PayloadAction<Adventure>) => {
      state.activeAdventure = action.payload;
    },
  },
});

export const { setActiveAdventure } = exploreSlice.actions;
export const selectActiveAdventure = (state: AppState) =>
  state.explore.activeAdventure;

export default exploreSlice.reducer;