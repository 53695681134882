import { createSlice } from '@reduxjs/toolkit';
import { AppState } from './store';

export interface ViewportState {
    ViewportSize:
    | 'small'
    | 'large'
}

const initialState: ViewportState = {
    ViewportSize: 'large',
}

export const viewportSlice = createSlice({
    name: 'viewport',
    initialState,
    reducers: {
        setSmallViewport: (state) => {
          state.ViewportSize = 'small';
        },
        setLargeViewport: (state) => {
          state.ViewportSize = 'large';
        },
    },
});

export const {setLargeViewport, setSmallViewport} = viewportSlice.actions;

export const selectViewport = (state: AppState) => state.viewport.ViewportSize;

export default viewportSlice.reducer;

