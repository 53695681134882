import { createSlice } from '@reduxjs/toolkit';
import type { AppState } from './store';

export interface Stuff {
  userId: number;
  id: number;
  title: string;
  completed: boolean;
}

export interface BackgroundState {
  backgroundPosition:
    | 'centered'
    | 'centered-higher'
    | 'centered-lower'
    | 'centered-lower-small'
    | 'bottom'
    | 'bottom-lower';
}

const initialState: BackgroundState = {
  backgroundPosition: 'centered',
};

export const backgroundSlice = createSlice({
  name: 'background',
  initialState,
  reducers: {
    setCentered: (state) => {
      state.backgroundPosition = 'centered';
    },
    setBottom: (state) => {
      state.backgroundPosition = 'bottom';
    },
    setCenteredHigher: (state) => {
      state.backgroundPosition = 'centered-higher';
    },
    setCenteredLower: (state) => {
      state.backgroundPosition = 'centered-lower';
    },
    setCenteredLowerSmall: (state) => {
      state.backgroundPosition = 'centered-lower-small';
    },
    setBottomLower: (state) => {
      state.backgroundPosition = 'bottom-lower';
    },
  },
});

export const { setCentered, setBottom, setBottomLower, setCenteredHigher, setCenteredLower, setCenteredLowerSmall } =
  backgroundSlice.actions;
export const selectBackgroundState = (state: AppState) =>
  state.background.backgroundPosition;

export default backgroundSlice.reducer;
