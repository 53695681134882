import { createSlice } from '@reduxjs/toolkit';

import type { AppState, AppThunk } from './store';

export interface Stuff {
  userId: number;
  id: number;
  title: string;
  completed: boolean;
}

export interface ModalState {
  stuff: Stuff;
  isModalOpen: boolean;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: ModalState = {
  stuff: {} as Stuff,
  isModalOpen: false,
  status: 'idle',
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state) => {
      state.isModalOpen = true;
    },
    closeModal: (state) => {
      state.isModalOpen = false;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;
export const selectModalState = (state: AppState) => state.modal.isModalOpen;

// Here's an example of conditionally dispatching actions based on current state.
export const toggleModal = (): AppThunk => (dispatch, getState) => {
  const currentValue = selectModalState(getState());
  currentValue === true ? dispatch(closeModal()) : dispatch(openModal());
};

export default modalSlice.reducer;
