import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { AppState } from './store';
import { Product } from './types';

export interface ModalState {
  product: Product;
}

const initialState: ModalState = {
  product: {} as Product,
};

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProductData: (state, action: PayloadAction<Product>) => {
      state.product = action.payload;
    }
  },
});

export const { setProductData } = productSlice.actions;
export const selectProductData = (state: AppState) => state.product.product;

export default productSlice.reducer;
