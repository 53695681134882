import { createSlice } from '@reduxjs/toolkit';
import type { AppState } from './store';

export interface ErrorState {
  title: string | null;
  message: string | null;
}

const initialState: ErrorState = {
  title: null,
  message: null,
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setErrorTitle: (state, action) => {
        state.title = action.payload;
      },
    setErrorMessage: (state, action) => {
      state.message = action.payload;
    },
  },
});

export const { setErrorTitle, setErrorMessage } = errorSlice.actions;

export const selectError = (state: AppState) => state.error;

export default errorSlice.reducer;
