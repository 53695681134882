import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppState } from './store';

export enum View {
  NONE = 'none',
  LOADING_SCREEN = 'loadingScreen',
  NOT_CONNECTED_HI = 'notConnectedHi',
  NOT_CONNECTED_START = 'notConnectedStart',
  CONNECT = 'connect',
  TAP_AND_CONNECT = 'tapAndConnect',
  CONNECTED_START = 'connectedStart',
  INTRO = 'intro',
  INTRO_CREATION = 'introCreation',
  EXPLORE ='explore',
}

export interface BackgroundState {
  activeView: View;
}

const initialState: BackgroundState = {
  activeView: View.NONE,
};

export const viewSlice = createSlice({
  name: 'view',
  initialState,
  reducers: {
    setActiveView: (state, action: PayloadAction<View>) => {
      state.activeView = action.payload as View;
    },
  },
});

export const { setActiveView } = viewSlice.actions;
export const selectActiveView = (state: AppState) =>
  state.view.activeView;

export default viewSlice.reducer;
