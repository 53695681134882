import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import modalReducer from './modalSlice';
import backgroundReducer from './backgroundSlice';
import productReducer from './productSlice';
import userReducer from './userSlice';
import viewReducer from './viewSlice';
import viewportReducer from './viewportSlice';
import contentReducer from './contentSlice';
import exploreReducer from './exploreSlice';
import errorReducer from './errorSlice';

export function makeStore() {
  return configureStore({
    reducer: {
      modal: modalReducer,
      background: backgroundReducer,
      product: productReducer,
      user: userReducer,
      view: viewReducer,
      viewport: viewportReducer,
      content: contentReducer,
      explore: exploreReducer,
      error: errorReducer,
    },
  });
}

const store = makeStore();

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

export default store;
