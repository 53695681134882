import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { AppState } from './store';
import { Content } from './types';
 
export interface ContentState {
  content: Content;
}

const initialState: ContentState = {
  content: {} as Content,
};

export const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    setContent: (state, action: PayloadAction<Content>) => {
      state.content = action.payload;
    },
  },
});

export const { setContent } = contentSlice.actions;
export const selectContent = (state: AppState) => state.content.content;

export default contentSlice.reducer;