import { createSlice } from '@reduxjs/toolkit'
import { AppState } from './store'
import { Adventures, Consent } from './types'

export interface UserState {
  userName: string
  email: string
  connected: boolean
  adventures: Adventures | []
  consent: Consent
}

const initialState: UserState = {
  userName: '',
  email: '',
  connected: false,
  adventures: [],
  consent: null
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserName: (state, action) => {
      state.userName = action.payload
    },
    setEmail: (state, action) => {
      state.email = action.payload
    },
    setConnected: (state) => {
      state.connected = true
    },
    setUnconnected: (state) => {
      state.connected = false
    },
    setAdventures: (state, action) => {
      state.adventures = state.adventures = action.payload
    },
    setConsent: (state, action) => {
      state.consent = action.payload
    }
  }
})

export const {
  setUserName,
  setEmail,
  setConnected,
  setUnconnected,
  setAdventures,
  setConsent
} = userSlice.actions

export const selectUser = (state: AppState) => state.user

export default userSlice.reducer
